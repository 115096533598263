export default {
  // 高棉语
  word1001: 'Khmer',
  word1002: 'អ៊ីមែល',
  word1003: 'ពាក្យសម្ងាត់',
  word1004: 'ភ្លាមខ្មែរពាក្យសម្ងាត់?',
  word1005: 'ចូលប្រើប្រាស់',
  word1006: 'ចុះឈ្មោះ',
  word1007: 'ការប្រគល់សន្និយមអ្នកប្រើ',
  word1008: 'ធនធានផ្ទាល់ខ្លួន',
  word1009: 'អំពីយើង',
  word1010: 'គណនីមិនអាចទទេ',
  word1011: 'ពាក្យសម្ងាត់មិនអាចទទេ',
  word1012: 'គណនី​នេះ​ត្រូវបាន​ភ្លាមៗ',
  word1013: 'ឈ្មោះគណនី ឬលេខសម្ងាត់មិនត្រឹមត្រូវ',
  word1014: 'ការចូលដំណើរការជោគជ័យ',
  word1015: 'ឈ្មោះអ្នកប្រើប្រាស់',
  word1016: 'បញ្ចូលលេខពីឆ្វេង',
  word1017: 'ទាញយកកូដបញ្ជាក់',
  word1018: 'លេខសម្ងាត់ធ្វើឡើងវិញ',
  word1019: 'លេខមិនអាចទទេ',
  word1020: 'កូដបញ្ជាក់មិនត្រឹមត្រូវ',
  word1021: 'លេខសម្ងាត់ពីរដងមិនត្រូវគ្នា',
  word1022: 'ឈ្មោះគណនាមិនអាចទទេបាន',
  word1023: 'អ៊ីមែលមិនអាចទទេបាន',
  word1024: 'វាយបញ្ចូលពាក្យសម្ងាត់ឡើងវិញមិនអាចទទេបាន',
  word1025: 'ព្យាយាម​ម្តង​ទៀត​ក្នុង"វិនាទី"វិនាទី',
  word1026: 'ដំណើរការចុះឈ្មោះជោគជ័យ',
  word1027: 'បញ្ជាក់ត្រាស័ត្តអ៊ីមែល',
  word1028: 'ត្រាស័ត្តអ៊ីមែលមិនអាចទទេបាន',
  word1029: 'ពាក្យសម្ងាត់ថ្មី',
  word1030: 'កែប្រែពាក្យសម្ងាត់',
  word1031: 'ពាក្យសម្ងាត់ថ្មីមិនអាចទទេបាន',
  word1032: 'កែប្រែជោគជ័យ',
  word1033: 'ប្រព័ន្ធបណ្តាញហិរញ្ញវត្ថុនិងច្បាប់ជាមួយគ្នា',
  word1034: 'ប្រព័ន្ធចលctaភាគហ៊ុន',
  word1035: 'គណនាមិនអាចប្រើបានទេ បានបិទដោយអ្នកប្រើ',
  word1036: 'ចុះឈ្មោះ និងធ្វើការបញ្ជាក់ថាអ្នកជាក្រុមហ៊ុន',
  word1037: 'ឈ្មោះក្រុមហ៊ុន',
  word1038: 'អាសយដ្ឋានក្រុមហ៊ុន',
  word1039: 'លេខទូរស័ព្ទក្រុមហ៊ុន',
  word1040: 'មុខតំណែងនៅក្នុងក្រុមហ៊ុន',
  word1041: 'ផ្ទុកឡើងវិញប័ណ្ណអភិវឌ្ឍន៍ក្រុមហ៊ុន',
  word1042: 'អាចផ្ទុកឡើងវិញ​តែ​ឯកសារ JPG/PNG ហើយ​មិន​អាច​ធំ​ជាង​ 20MB',
  word1043: 'ផ្ទុកឡើងវិញអេក្រង់ពន្ធដាក់',
  word1044: 'ដាក់ស្នើ',
  word1045: 'មិនធ្វើការបញ្ជាក់ឥលូវនេះ ត្រូវរកមើលមុន',
  word1046: 'បទពិសោធន៍រូបភាព',
  word1047: 'សូមផ្ទុកឡើងវិញអេក្រង់អនុញ្ញាតគ្រប់គ្រងហាង',
  word1048: 'សូមផ្ទុកឡើងវិញអេក្រង់ពន្ធដាក់',
  word1049: 'ឈ្មោះក្រុមហ៊ុនមិនអាចទទេ',
  word1050: 'អាសយដ្ឋានក្រុមហ៊ុនមិនអាចទទេ',
  word1051: 'លេខទូរស័ព្ទក្រុមហ៊ុនត្រូវតែមាន',
  word1052: 'មុខតំណែងបច្ចុប្បន្នគួរតែមាន',
  word1053: 'លុបដោយជោគជ័យ',
  word1054: 'ដាក់ស្នើដោយជោគជ័យ',
  word1055: 'អឺរ៉ុប! អ៊ីមែលនេះត្រូវបានចុះឈ្មោះរួចហើយ សូមប្រើអ៊ីមែលផ្សេងទៀត',
  word1056: 'ការចុះឈ្មោះរបស់អ្នកមិនធ្វើការបញ្ជាក់ថាត្រូវបានគ្រប់គ្រងក្រុមហ៊ុនឡើយ',
  word1057:
    'ប្រព័ន្ធនេះត្រូវបានគេបង្កើតឡើងដោយផ្អែកលើផ្ទុកចូលរបស់ក្រុមហ៊ុនឆ្លងកាត់សំណុំ និងតាមតំណរភ្ជាប់ជាមួយក្រុមហ៊ុនដើម្បីផ្តល់ឱ្យការណែនាំពាណិជ្ជកម្មនិងប្រភេទទិន្នន័យច្បាប់ច្រើន។ យើងណែនាំអ្នកធ្វើការគ្រប់គ្រងក្រុមហ៊ុនដើម្បីទទួលបានសិទ្ធិច្បាប់ច្រើនជាង។',
  word1058: 'ការណែនាំ',
  word1059: 'មិនធ្វើការគ្រប់គ្រងឥឡូវនេះទេ',
  word1060: 'គ្រប់គ្រងក្រុមហ៊ុន',
  word1061: 'អ៊ីមែលអ្នកប្រើបានរកឃើញរួចហើយឬបានលុប',
  word1062: 'ប្រវែងពាក្យសម្ងាត់ត្រូវតែមានចំនួន 8 ដល់ 16 តួអក្សរ',
  word1063: 'ទម្រង់អ៊ីមែលមិនត្រឹមត្រូវ',
  word1064: 'កូដបញ្ជាក់អ៊ីមែលផុតពិន្ទុឬមិនបានផ្ញើ',
  word1065: 'កូដបញ្ជាក់អ៊ីមែលមានកំហុស',

  // 湘企出海系统翻译
  word2001: 'Thai Young Chinese Chamber of Commerce',
  word2002: 'ប្រព័ន្ធប្រតិបត្តិការសម្រាប់ជំនួយទាំងអស់គ្នា',
  word2003: 'ប្រព័ន្ធគ្រួសារមនុស្ស',
  word2004: 'គណនាឈ្មោះគណនានានាគារត្រូវបានបិទប្រើ',

  // 新增
  AccountPlaceholder: 'អ៊ីមែល',
  AccountEmptyTip: 'គណនាឈ្មោះគណនានានាគារអ៊ីមែលមិនអាចទទេ',
  PasswordPlaceholder: 'ពាក្យសម្ងាត់',
  PasswordEmptyTip: 'ពាក្យសម្ងាត់មិនអាចទទេ',
  AccountPasswordError: 'គណនាឈ្មោះគណនានានាគារ ឬ ពាក្យសម្ងាត់មិនត្រឹមត្រូវ',
  RightNumber: 'សូមបញ្ចូលលេខដែលមាននៅក្នុងរូបភាពខាង phải',
  NumberEmptyTip: 'លេខនៅក្នុងរូបភាពមិនអាចទទេ',
  EmailPlaceholder: 'អ៊ីមែល',
  GetEmailCode: 'ទទួលបានកូដបញ្ជាក់អ៊ីមែល',
  Retry: ' សាកល្បងម្តងទៀតក្នុង {វិនាទី}',
  Resend: 'ផ្ទុក​ឡើង​វិញ',
  EmailEmptyTip: 'គណនាឈ្មោះគណនានានាគារអ៊ីមែលមិនអាចទទេ',
  EmailCodePlaceholder: 'កូដបញ្ជាក់អ៊ីមែល',
  EmailCodeEmptyTip: 'កូដបញ្ជាក់អ៊ីមែលមិនអាចទទេ',
  InputNewPassword: 'សូមបញ្ចូលពាក្យសម្ងាត់ថ្មី',
  AgainInputNewPassword: 'សូមបញ្ចូលពាក្យសម្ងាត់ថ្មីឡើងវិញ',
  AgainInputPassword: 'សូមបញ្ចូលពាក្យសម្ងាត់ម្តងទៀត។',
  Confirm: 'បញ្ជាក់ជាបញ្ហា',
  RealName: 'នាមត្រកូល',
  RealNameEmptyTip: 'នាមត្រកូលមិនអាចទទេ',
  EmailCodeInvalid: 'កូដបញ្ជាក់អ៊ីមែលផុតពិន្ទុ សូមទទួលបានម្តងទៀត',
  RegisterCertify: 'ចុះឈ្មោះហើយធ្វើការបញ្ជាក់សម្រាប់ការឆ្លងកាត់',
  NotCertifyRegister: 'រងនាំមិនធ្វើការបញ្ជាក់ឥឡូវនេះ សូមចុះឈ្មោះប៉ុណ្ណោះ',
  NotCertifyTip:
    'ប្រព័ន្ធនេះត្រូវបានបង្កើតឡើងដោយផ្តល់ជូនសម្រាប់ការឆ្លងកាត់ពីរបៀបប្រចាំឆ្នាំ ហើយស្រាប់ឧបករណ៍សិក្សាអភិវឌ្ឍន៍ហើយនឹងអត្ថបទច្បាប់ទៅអ្នក។ យើងសូមណែនាំអ្នកធ្វើការបញ្ជាក់សម្រាប់ក្រុមហ៊ុនដើម្បីទទួលបានសិទ្ធិច្បាប់ច្រើនជាង។',
  GoCertify: 'ទៅកាន់ការបញ្ជាក់',
  FileSize: 'សូម​ផ្ទុកឡើង​វិញ​ fail ជាមួយ​ទម្រង់ .jpg ឬ .png, ហើយ​ទំហំ fail មិន​គួរ​តែ​ធំ​ជាង 20MB។',
  AccountInexistence: 'គណនាឈ្មោះគណនានានាគារនេះ​មិន​មាន​ទេ ហើយ​មិនអាច​កែប្រែ​ពាក្យសម្ងាត់។',
  DeleteTip: 'គណនីរបស់អ្នកត្រូវបានលុបចេញហើយ។ សូមទាក់ទងអ្នកគ្រប់គ្រងក្រុមហ៊ុនរបស់អ្នក។',
  DearUser: 'ស្រលាញ់អ្នកប្រើប្រាស់，',
  NotShow: 'មិនបង្ហាញ​ឡើង​វិញ',
  PromptContentOne: 'សួស្តី។ ប្រព័ន្ធនេះគឺ ',
  PromptContentTwo: ' សម្រាប់អ្នកប្រើប្រាស់ប្រព័ន្ធទូទាត់ឥតគិតថ្លៃដើម្បីបង្កើតប្រព័ន្ធអេឡិចត្រូនិចសម្រាប់ក្រុមហ៊ុន ',
  PromptContentThree:
    ' ព័ត៌មានអំពីអាជីវកម្មនិងសេវាកម្មផ្សេងៗដែលប្រើប្រាស់ដោយអ្នកប្រើប្រាស់អាជីវកម្ម។ សូមបញ្ជាក់ថាអ្នកជាប់ធានារ៉ាប់រង្គមនិងអ្នកគ្រប់គ្រងផ្នែកទីផ្សារ ផ្នែកលក់ ផ្នែកទិញ និងផ្នែកច្បាប់របស់ក្រុមហ៊ុននៅប្រទេសម៉ាឡេស៊ី។ សូមអានយ៉ាងត្រឹមត្រូវ។ ',
  PromptContentFour:
    ' មុនពេលចុះឈ្មោះ សូមបំពេញពត៌មានផ្ទាល់ខ្លួនរបស់អ្នកដោយសារតែត្រឹមត្រូវ។ សូមចងចាំថាស្ទូមស្ថានភាពការគ្រប់គ្រងផ្ទៃក្នុងរបាយការណ៍របស់យើងនឹងបង្កើនឬលុបគណនីដែលមិនមែនជាវិស្វករ។ ',
  PromptContentFive: 'អរគុណសម្រាប់ការចូលរួមរបស់អ្នក។ ',
  PromptContentSix: ' ហើយអរគុណសម្រាប់ការគាំទ្ររបស់អ្នក!',
  PromptContentSeven: ' ការិយាល័យ',
  QuickPaymentTip:
    'មហាវិទ្យាល័យពាណិជ្ជកម្មរបស់អ្នកបានកំណត់ចំណាយសម្រាប់សិស្សសម្រាប់សិក្សាបន្ទាប់មក។ សូមគាំទ្របានឆាប់ៗ ឬអ្នកនឹងមិនឃើញបញ្ហានៅក្នុង [ថ្ងៃត្រូវការ] ទេ។',
  UnableLoginTip: 'មិនអាចចូលបាន',
  Prompt: 'ការអំពីរបៀប',
  MembershipDues: 'បង់ប្រាក់សម្រាប់ការជាវិស្វកម្ម',
  Determine: 'បញ្ជាក់ប្រាកដ',
  MembershipExpirationTip: 'សមាជិករបស់អ្នកបានផ្លាស់ប្តូរស្ថានភាព។ សូមបង់ប្រាក់សម្រាប់សមាជិកឡើងវិញ។',
  Cancel: 'បោះបង់',
  ImgOnly: 'ទម្រង់រូបភាពអាចគ្រាន់តែជា jpeg、jpg、png、bmp、tiff ប៉ុណ្ណោះ',
  PictureSize2MBTip: 'ទំហំរូបភាព​មិន​គួរ​លើស​ពី 2MB',
  UploadSuccess: 'បង្ហោះជោគជ័យ'
}
