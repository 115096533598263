export default {
  // 越南语
  word1001: 'Vietnamese',
  word1002: 'Thư điện tử',
  word1003: 'Mật khẩu',
  word1004: 'Quên mật khẩu?',
  word1005: 'Đăng nhập',
  word1006: 'Đăng ký',
  word1007: 'Thỏa thuận người dùng',
  word1008: 'Riêng tư cá nhân',
  word1009: 'Về chúng tôi',
  word1010: 'Tài khoản không được để trống',
  word1011: 'Mật khẩu không được để trống',
  word1012: 'Tài khoản đã bị khóa',
  word1013: 'Tên đăng nhập hoặc mật khẩu không đúng',
  word1014: 'Đăng nhập thành công',
  word1015: 'Tên hiển thị',
  word1016: 'Nhập số bên phải',
  word1017: 'Nhận mã xác nhận',
  word1018: 'Mật khẩu xác nhận',
  word1019: 'Không được để trống ô chứa số',
  word1020: 'Mã xác nhận không chính xác',
  word1021: 'Hai mật khẩu nhập vào không khớp nhau',
  word1022: 'Tên biệt danh không được để trống',
  word1023: 'Địa chỉ email không được để trống',
  word1024: 'Mật khẩu xác nhận không được để trống',
  word1025: 'Thử lại sau vài giây',
  word1026: 'Đăng ký thành công',
  word1027: 'Mã xác minh email',
  word1028: 'Mã xác minh không được để trống',
  word1029: 'Mật khẩu mới',
  word1030: 'Thay đổi mật khẩu',
  word1031: 'Mật khẩu mới không được để trống',
  word1032: 'Cập nhật mật khẩu thành công',
  word1033: 'Hệ thống kết nối kinh doanh xuyên biên giới & pháp lý',
  word1034: 'Hệ thống dịch vụ tổng hợp',
  word1035: 'Tài khoản của bạn đã bị khóa',
  word1036: 'Đăng ký và xác thực thông tin công ty',
  word1037: 'Tên công ty',
  word1038: 'Địa chỉ công ty',
  word1039: 'Số điện thoại công ty',
  word1040: 'Chức danh hiện tại',
  word1041: 'Tải lên Giấy phép đăng ký kinh doanh',
  word1042: 'Chỉ được tải lên file định dạng JPG/PNG và dung lượng không vượt quá 20MB',
  word1043: 'Tải lên Chứng nhận thuế',
  word1044: 'Đưa lên',
  word1045: 'Không cần xác thực ngay, trước tiên hãy duyệt xét',
  word1046: 'Xem trước ảnh',
  word1047: 'Hãy tải lên Giấy chứng nhận đăng ký kinh doanh',
  word1048: 'Hãy tải lên Chứng minh thư thuế của công ty',
  word1049: 'Tên công ty không được để trống',
  word1050: 'Địa chỉ công ty không được để trống',
  word1051: 'Số điện thoại công ty không được để trống',
  word1052: 'Chức vụ hiện tại không được để trống',
  word1053: 'Xóa thành công',
  word1054: 'Đã gửi thành công',
  word1055: 'Xin lỗi, email này đã được đăng ký. Vui lòng sử dụng một email khác.',
  word1056: 'Đăng ký tạm thời chưa thực hiện xác thực công ty',
  word1057:
    'Hệ thống này chuyên dụng cho giao thương xuyên biên giới, được thiết kế riêng cho từng doanh nghiệp, nhằm cung cấp nhiều cơ hội kinh doanh hơn và nguồn tài nguyên pháp lý. Chúng tôi đề nghị bạn tiến hành xác thực công ty để có thêm nhiều quyền lợi.',
  word1058: 'Cảnh báo',
  word1059: 'Không xác thực lúc này',
  word1060: 'Xác thực công ty',
  word1061: 'Email của người dùng đã tồn tại hoặc đã bị xóa',
  word1062: 'Độ dài mật khẩu phải từ 8 đến 16 ký tự',
  word1063: 'Định dạng email không chính xác',
  word1064: 'Mã xác nhận email đã hết hạn hoặc chưa được gửi đi',
  word1065: 'Mã xác nhận email sai',

  // 湘企出海系统翻译
  word2001: 'Thai Young Chinese Chamber of Commerce',
  word2002: 'Hệ thống dịch vụ tổng hợp',
  word2003: 'Hệ thống Thành viên',
  word2004: 'Tài khoản này đã bị vô hiệu hóa',

  // 新增
  AccountPlaceholder: 'Thư điện tử',
  AccountEmptyTip: 'Tên tài khoản email không được để trống',
  PasswordPlaceholder: 'Mật khẩu',
  PasswordEmptyTip: 'Mật khẩu không được để trống',
  AccountPasswordError: 'Tên đăng nhập hoặc mật khẩu không chính xác',
  RightNumber: 'Vui lòng nhập số trong hình ảnh bên phải',
  NumberEmptyTip: 'Số trong hình ảnh không được để trống',
  EmailPlaceholder: 'Thư điện tử',
  GetEmailCode: 'Nhận mã xác nhận email',
  Retry: ' Thử lại sau {giây} giây',
  Resend: 'Gửi lại',
  EmailEmptyTip: 'Tên tài khoản email không được để trống',
  EmailCodePlaceholder: 'Mã xác nhận email',
  EmailCodeEmptyTip: 'Mã xác nhận email không được để trống',
  InputNewPassword: 'Vui lòng nhập mật khẩu mới',
  AgainInputNewPassword: 'Hãy nhập lại mật khẩu mới',
  AgainInputPassword: 'Hãy nhập lại mật khẩu',
  Confirm: 'Đồng ý',
  RealName: 'Họ và tên thật',
  RealNameEmptyTip: 'Họ và tên thật không được để trống',
  EmailCodeInvalid: 'Mã xác nhận email không hợp lệ, vui lòng lấy lại mã.',
  RegisterCertify: 'Đăng ký và thực hiện xác thực',
  NotCertifyRegister: 'Không tiến hành xác thực ngay, đăng ký trực tiếp',
  NotCertifyTip:
    'Hệ thống này chuyên dụng cho giao thương xuyên biên giới, chúng tôi khuyên bạn nên xác thực doanh nghiệp để tận dụng nhiều cơ hội kinh doanh hơn và các nguồn tài nguyên pháp lý.',
  GoCertify: 'Đi đến xác thực',
  FileSize: 'Vui lòng tải lên tệp định dạng .jpg hoặc .png, với dung lượng file không vượt quá 20MB.',
  AccountInexistence: 'Tài khoản không tồn tại, không thể thay đổi mật khẩu.',
  DeleteTip: 'Tài khoản của quý vị đã bị xóa. Vui lòng liên hệ với quản trị viên tổ chức thương mại của quý vị.',
  DearUser: 'Quý khách hàng kính trọng,',
  NotShow: 'Không hiển thị nữa',
  PromptContentOne: 'Xin chào, hệ thống này là ',
  PromptContentTwo: ' để thành viên doanh nghiệp tạo ra một hệ thống IT nội bộ, tổng hợp ',
  PromptContentThree:
    ' thông tin kinh doanh và tài nguyên pháp lý cho việc sử dụng của doanh nghiệp, phù hợp với các thành viên của doanh nghiệp, giám đốc điều hành, phòng marketing, phòng bán hàng, phòng mua hàng và trưởng phòng pháp lý chịu trách nhiệm về việc sử dụng. Các quản lý, vui lòng xác nhận rằng công ty của bạn là thành viên của ',
  PromptContentFour:
    ' trước khi đăng ký, và trung thực điền thông tin cá nhân của bạn. Xin lưu ý rằng hệ thống quản lý nền của chúng tôi sẽ đóng băng hoặc xoá các tài khoản không phải thành viên.',
  PromptContentFive: 'Cảm ơn sự quan tâm của bạn ',
  PromptContentSix: ' và cảm ơn bạn vì sự hỗ trợ của bạn!',
  PromptContentSeven: ' Ban Thư ký',
  QuickPaymentTip:
    'Hội thương mại của bạn đã thiết lập phí hội viên. Vui lòng thanh toán sớm nhất có thể, nếu không, bạn sẽ đối mặt với hậu quả vào [ngày cụ thể]',
  UnableLoginTip: 'Không thể đăng nhập',
  Prompt: 'Thông báo',
  MembershipDues: 'Nộp phí hội viên',
  Determine: 'Xác nhận',
  MembershipExpirationTip: 'Thẻ hội viên của bạn đã hết hạn. Vui lòng thanh toán phí thành viên để gia hạn',
  Cancel: 'Hủy bỏ',
  ImgOnly: 'Định dạng hình ảnh chỉ có thể là jpeg、jpg、png、bmp、tiff',
  PictureSize2MBTip: 'Kích thước hình ảnh không được vượt quá 2MB',
  UploadSuccess: 'Tải lên thành công'
}
